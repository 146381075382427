import { Button, IconButton } from '@mui/material'
import React from 'react'

const IconBtn = ({title,func,icon}) => {
  return (
        <Button  sx={{color:'primary.text',backgroundColor:'primary.main'}} variant="contained" endIcon={icon} onClick={func}>
            {title}
        </Button>
  )
}

export default IconBtn