import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Style } from './Style'
import { CardContent, CardMedia, Grid, Typography } from '@mui/material';
import Vals from '../../config/Vars';
import { API, CalculateTime } from '../../config/functions';

const SideNews = ({nav}) => {
    
    const [data, setData] = useState([])

    useEffect(() => {
        API.get(`/top-blogs/` , Vals?.GET_HEADER).then((res) => setData(res?.data?.data) ).catch((e) => console.log(e))
    } , [])
 
    const Card = ({item}) => (
        <Box onClick={() => nav(`/blog/${item?.blog_id}`)} sx={{ display: 'flex' , marginBottom : 2 , width : '100%' ,cursor:'pointer'}}>
            <CardMedia
                sx={{width: '20%' ,height:100}}
                image={`${Vals?.M_API}/blogs/${item?.blog_image?item?.blog_image:'empty.png'}` }
                title="Blog Cover Image"
            />
            <div style={{  display: 'flex',flexDirection: 'column',width:'80%'}}>
                <CardContent style={{flex: '1 0 auto',}}>
                    <Typography component="h5" variant="h5" color={'primary.text'}>
                    {item?.blog_title}
                    </Typography>
                    <Typography variant="subtitle1" color={'primary.text'}>
                    {  new Date(item?.blog_date).toLocaleString()}
                    </Typography>
                </CardContent>
            </div>
        </Box>
    )

    return (
        <Box sx={[Style?.Container , { backgroundColor:'primary.main' }]}>
            <Typography variant='h6' color='primary.text'> Top News </Typography>
            <Box sx={[Style?.InnerContainer , { borderColor:'primary.border' }]}>
                <Grid container spacing={2}>
                    {data?.map((item, index) => (
                        <Grid item xs={12} sm={12} key={index} sx={{borderStyle:'solid',borderColor:'primary.border',borderWidth:0,borderTopWidth:1}}>
                            <Card {...{item}}  />
                        </Grid>
                    ))} 
                </Grid>
            </Box>
        </Box>
    )
}

export default SideNews