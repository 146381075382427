import { Avatar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Vals from '../../config/Vars'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

const MemberCard = ({item,nav}) => {
    const Style = {
        Container : {
            width:{md:'95%',xs:'90%'},
            borderStyle:'solid',
            borderWidth:1,
            borderColor:'primary.border',
            p:2,
            justifyContent:'flex-start',
            alignItems:'center',
            display:'flex',
            flexDirection:'row',
            cursor : 'pointer',
        },
        info:{
            justifyContent:'flex-start',
            display:'flex',
            flexDirection:'column' ,
            ml:2
        },
        profilePic : { 
            height:100,
            width:100,
        } , 
        iconContainer : {
            justifyContent:'flex-start',
            alignItems:'center',
            display:'flex',
            flexDirection:'row' ,
        }
    }
    
    const visit = () => {
        nav(`/profile/${item?.user_id}`)
    }
    
    const BorderColor = (e) => {
        var val = 'transparent'
        if (e == 0) {
          val = 'transparent'
        } else if ( e == 1 ) {
          val = '#d0cccc'
        } else if ( e == 2 ) {
          val = '#ceb99d'
        } else if ( e == 3 ) { 
          val = '#c9ac2e'
        }
        return val
    }

    const img = item?.user_profile_pic ? `${Vals?.M_API}/profile-pictures/${item?.user_profile_pic}` : '/logo.png'

  return (
    <Box sx={Style?.Container} onClick={visit}>
        <Box sx={{
                borderStyle:'solid',
                borderWidth:2,
                borderRadius:100,
                borderColor:BorderColor(item?.accountLevel)
            }}>
            <Avatar src={img}  sx={Style?.profilePic} />
            {item?.user_verified === 'y' && ( 
                <Box sx={{position:'relative'}}>
                    <VerifiedRoundedIcon sx={{color:'gold',position:'absolute',right:0,bottom:0,fontSize:25 }} />
                </Box>             
            )}
        </Box>

        <Box sx={Style?.info}>
            <Typography color='primary.text' fontSize={25}> {item?.user_username} </Typography>
            <Box sx={Style?.iconContainer}>
                <Typography color='primary.text'> {item?.member_user_type} </Typography>
            </Box>
        </Box>
    </Box>
  )
}

export default MemberCard