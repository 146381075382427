import { Box, Typography } from '@mui/material'
import React from 'react'

const Empty = ({text}) => {
    
    const Style = {
        height:'auto',
        p:3,        
        borderColor: `primary.border`,        
        borderStyle: 'solid' , 
        borderWidth: 0.5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'center',
        alignItems: 'center',  
    }

  return (
    <Box sx={Style}>
        <Typography color='primary.text' fontSize={12}> {text} </Typography>
    </Box>
  )
}

export default Empty