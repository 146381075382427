import { Avatar, Backdrop, Box, Button, CardMedia, CircularProgress, IconButton, Typography } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { AlertsDialog, Btn, IconBtn, PageHeader, TextInput } from "../../components"
import { API, HandleCreate64ForState } from "../../config/functions"
import { useData } from "../../config/Provider"
import Vals from "../../config/Vars"
import { Style } from "./Style"
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useParams } from "react-router"
import { FALSE } from "sass"

const  Support = ({nav}) =>  {
 
    const [ load , setLoad ] = useState(true)
    const [ profilePic , setProfilePic ] = useState([])
    const [ cover , setCover ] = useState([])
    const [ data , setData ] = useState([])
    const {user} = useData()
    const {id} = useParams()
    const [ title , setTitle ] = useState('')
    const [ des , setDes ] = useState('')
    const [ showAlert , setShowAlert ] = useState(false)
 
    const save = async () => {
        setLoad(true)
        setTimeout(() => {
            setLoad(false)
            setShowAlert(true)
            setTitle('')
            setDes('')
        }, 1500);
    }

    useEffect(() => {
        setTimeout(() => {
            setLoad(false)
        }, 1500);
    } , [])
    return ( 
        <Box sx={[ , {height:'100%', }]}>

        <AlertsDialog {...{
          showAlert:showAlert,
          setShowAlert:setShowAlert,
          alertText:'Thank you for contacting Us, we will get back to you as soon as possible',
        }} />
    
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={load}
        >
      <CircularProgress color="inherit" />
      </Backdrop>
            <PageHeader {...{title:'Support',func:save,btnTitle:'Send'}} />
            
            <Box sx={[Style?.inputItem , { borderColor:'primary.border' }]}>

                <Box sx={Style?.Label}>
                    <Typography sx={{color:'primary.text'}}> Email </Typography>
                </Box>

                <Box sx={Style?.input}>
                    <TextInput {...{value:title,set:setTitle,placeholder:'me@example.com',type:'text',multiline:false}} />
                </Box>

            </Box>
            <Box sx={[Style?.inputItem , { borderColor:'primary.border' , mb:2 }]}>

                <Box sx={Style?.Label}>
                    <Typography sx={{color:'primary.text'}}> Message </Typography>
                </Box>

                <Box sx={Style?.input}>
                    <TextInput {...{value:des,set:setDes,placeholder:'Type...',type:'text',multiline:true,rows:5}} />
                </Box>

            </Box>

            <Box sx={{  width:'100%'  , borderBottomWidth:0.2 , borderColor:'primary.border' }} />
 

        </Box>

    )
  
}
 
export default Support