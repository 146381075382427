import { Grid, IconButton, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { useData } from '../../config/Provider'
import { Empty, PostCard } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/system'

export default function Following({nav}) {

  const [ data , setData ] = useState([])
  const [ page , setPage ] = useState(0)
  const {user} = useData()
  const [check , setCheck] = useState(true)

  document.title='SYPHEX | Home'
  
  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
      await API.get( `/followed-users-posts/${page}/` , Vals?.GET_HEADER )
      .then( ( response ) => {
        if (page == 0) {
        setData(response?.data?.data);
        } else {
        if ( response?.data?.data?.length == 0 ) {
          setCheck(false)
        } else {
          setCheck(true)
          setData((prevData) => [...prevData, ...response?.data?.data]);
        }
        }
      })
      .catch((e) => console.log(e) )

  };

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const documentHeight = document.documentElement.scrollHeight;
    if ( scrollY + windowHeight >= documentHeight  ) {
      setPage((prevPage) => check ? prevPage + 10 : page );
    }
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, [check]);  

  return (
    <Box  >
      {data?.length > 0 ? data?.map((post, key) => <PostCard key={key} {...{nav, post}} />) : <Empty {...{text:'No posts found'}} />} 
      <Box sx={{height: 100}} />
    </Box>
  );
}