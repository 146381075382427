import { AppBar, IconButton, Menu, Badge, MenuItem, Grid, Avatar, FormControl, OutlinedInput, InputBase, CardMedia, Tooltip, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import { Style } from './Style';
import { Box } from '@mui/system';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Groups3Icon from '@mui/icons-material/Groups3';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Prv, useData } from '../../config/Provider';
import Btn from '../Btn/Btn';
import { API, useLocalState } from '../../config/functions';
import Vals from '../../config/Vars';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

const MobileTopBar = ({nav}) => {

  const [ Islogged , setIslogged ] = useLocalState("Islogged" );
  const [ search , setSearch ] = useState("");

  const { Color , logged , setlogged , notifyNum} = useData()

  const handleSubmit = (e) => {
    e.preventDefault();
    nav(`/search/${search}`)
  };

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };
 
  return (
    <AppBar position="static" sx={[Style?.Container , { backgroundColor:Color?.main }]}>
        
        <Box sx={Style?.InnerContainer}>
            <Avatar sx={Style?.Logo} alt="SYPHEX" src="/logo.png"  onClick={()=> nav('/')}>S</Avatar>
            <FormControl >
            <InputBase   value={search}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}  
                sx={{ borderRadius:3 , p:1 , pl:2  , ml: 1, flex: 1 , color: 'primary.text' , backgroundColor:'primary.bg' , borderWidth : 2 , borderColor:'primary.border'}}
                placeholder="Search"
            />
            </FormControl>
            <IconButton aria-label="delete" size="small" onClick={() => nav('/mobile-nav/')}>
                <ViewWeekIcon fontSize="inherit" sx={{color: 'primary.text'}} />
            </IconButton>
        </Box>
 
    </AppBar>
  );
};

export default MobileTopBar