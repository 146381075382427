import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'

const Activate = ({nav}) => {

    const {token} = useParams()
    const [work, setwork] = useState(false)
    const [load, setLoad] = useState(true)
    document.title='SYPHEX | Activate account'

    const check = async () => {

        try {

            await API.post(`/activate/${token}`, Vals?.POST_HEADER)
    
            .then(function (response) {

                if (response.data.status === false) {
                    setwork(false)
                } 
        
                if (response.data.status === true) {
                    setwork(true)
                } 

                setLoad(false)

            })
      
        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {
        check()
        setTimeout(() => {
            nav('/login/')       
        }, 3000);
    } , [])

    if (!load)
    return (
        <Box sx={{
            p:1,
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column',
            textAlign:'center',
            color:'white',
            height:500
        }}>
            <Typography variant='h6' >{work ? 'Account activated successfully':'Something went wrong!'}</Typography>        
            <Typography variant='p' >{work ? 'You can login into your account':'Try to reset password'}</Typography>        
        </Box>
    )
}

export default Activate