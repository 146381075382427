import { Backdrop, Box, CircularProgress, IconButton, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Btn, DiscussionsCard, SelectDiscussion, TextInput } from '../../components'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { API } from '../../config/functions';
import Vals from '../../config/Vars';
import { useData } from '../../config/Provider';

const AddPost = ({nav}) => {
  
  const [section, setSection] = useState(false)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [media, setMedia] = useState([])
  const [discussion, setDiscussion] = useState(0)
  const [discussions, setDiscussions] = useState([])
  var rows = []
  const {user} = useData()
  const scrollableContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [loading, setLoading] = useState(false);
  document.title='SYPHEX | Add post'

  const getData =  async () => {
    await API.get(`/get-user-discussions/` , Vals?.GET_HEADER  )
    .then( ( response ) => {
        setDiscussions(response?.data?.data)
    })
    .catch((e) => console.log(e) )
  }

  useEffect(() => {
    getData()
  }, [])

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStartX(event.clientX);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      const dx = event.clientX - dragStartX;
      scrollableContainerRef.current.scrollLeft = scrollLeft - dx;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setScrollLeft(scrollableContainerRef.current.scrollLeft);
  };

  const Next = () => {
    if ( !title.trim() ) return false
    setSection(true)
  }

  const Prev = () => {
    setSection(false)
  }

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const Submit = async () => {
    setLoading(true)
    if (discussion == 0) return false

    try {

      var filesType = []
      var data = new FormData();
      
      data.append('title', title);
      data.append('discussion_id', discussion);
      data.append('description', body);
      
      media.forEach((image, index) => {

        if (media[index]?.type === 'image') {
          const blob = dataURItoBlob(media[index]?.file)
          data.append(`image${index++}` , blob ,  `${index++}${image?.name}`)
        } else {
          data.append(`image${index++}`  ,  image?.uri)
        }

        filesType?.push({
          type : image?.type ,
          name : `${index++}${image?.name}`,
        })

      })

      data.append(`filesType`, JSON.stringify(filesType) );

      await API.post(`/upload-post/`, data , Vals?.POST_UPLOAD_HEADER )
      
      .then(function (response) {

        if (response?.data?.status === true ){ 
          nav(`/profile/${user}`)
        } else {
          console.log(  response?.data?.msg )
        }
        setLoading(false)
      }).catch(function (error) {
      
        console.log(error)
      
      });

    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    console.log(media)
  
  }, [ ])

  const handleCreate64 = useCallback(async (e) => {
    const file = e.target.files[0]
    const base64 = await ToBase64(file)
  
    setMedia([...media, {
      uri : file,
      type : 'image' ,
      file : base64 ,
      name : file.name,
      exn : file.name.substr(file.name.lastIndexOf('.') + 1) 
    }])
  
    e.target.value = ''
  }, [media])

  const addVid = useCallback(async (e) => {
    const file = e.target.files[0]
    const videoURL = URL.createObjectURL(file);
  
    setMedia([...media, {
      uri : file ,
      type : 'video' ,
      file : videoURL ,
      name : e.target.files[0].name,
      exn : e.target.files[0].name.substr( e.target.files[0].name.lastIndexOf('.') + 1 ) 
    }])
  
    e.target.value = ''
  }, [media])

  const ToBase64 = (file) => {
    return new Promise( (resolve,reject) => {
      const fileReader = new FileReader()
      if (file) {
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
          resolve(fileReader.result)
        }
      }

      fileReader.onerror = (error) => {
        reject(error)
      }

    })
  }

  const deleteMedia = (e) => {
    const deleted  = media.filter((item) => item?.file !== e) 
    setMedia(deleted);
  }

  return (
    <Box sx={{
      borderStyle:'solid',
      borderColor:'primary.border',
      borderWidth:1,
      p:2
    }}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        >
      <CircularProgress color="inherit" />
      </Backdrop>


      <Box sx={{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
      }}>
          <Typography color='primary.text' variant='h6'>{section? 'Select discussion':'Whats in your mind'}</Typography>
       {section? (
        <Box sx={{
          display:'flex',
          justifyContent:'flex-end',
          alignItems:'center',
        }}>
          <Btn {...{title:'back',func:Prev}} />
          <Btn {...{title:'post',func:Submit}} />
        </Box>
       ) : (
          <Btn {...{title:'next',func:Next}} />
       )} 
      </Box>
      {section ? 
      
      <Box mt={2}>
        {discussions?.map((item,key) => (<SelectDiscussion key={key} {...{item,nav,val:discussion,set:setDiscussion}}/>) )}
      </Box>      
      
      :
      <>
        <TextInput {...{placeholder:'Title',set:setTitle,value:title}} />
        <TextInput {...{placeholder:'Body `optional`',set:setBody,value:body,multiline:true,rows:5}} />
        <Box mt={2}>
          <IconButton color="primary" aria-label="upload picture" component="label">
            <input hidden onChange={handleCreate64} accept="image/*" type="file" />
            <PhotoCamera sx={{color:'primary.text'}}/>
          </IconButton>
          <IconButton color="primary" aria-label="upload picture" component="label">
            <input hidden onChange={addVid} accept="video/*" type="file" />
            <PlayCircleOutlineIcon sx={{color:'primary.text'}}/>
          </IconButton>
        </Box>
       
          {media?.length > 0 && (

          <Box sx={{
            display: 'flex',
            overflowX: 'auto',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              height: '8px',
              width: '8px',
              backgroundColor: '#F5F5F5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '4px',
              backgroundColor: '#9E9E9E',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#757575',
            },
          }}
          
 
          ref={scrollableContainerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          
          >
            {media?.map((item,key) => (
                <Box key={key} sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                  {item?.type === 'image' ?  
                    <img src={item?.file} style={{height:100}}/>
                  : 
                    <video  style={{height:100}}>
                      <source src={item?.file} type="video/mp4" />
                    </video>
                  } 
                  <IconButton color="primary" aria-label="upload picture" component="label"  onClick={() => deleteMedia(item?.file) }>
                    <DeleteTwoToneIcon sx={{color:'primary.text'}}/>
                  </IconButton>
                </Box>
            ))}
          </Box>

          )}
        

      </>
      }
    </Box>
  )
}

export default AddPost