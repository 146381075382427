export const Style = {
    Container : {
        width:'100%' ,
        p:2,
        height:'auto',
    } ,
    InnerContainer : {
        borderWidth:0,
        borderTopWidth:1,
        borderStyle:'solid',
        mt:1 ,
        mb:1 , 
        pt : 2
    } ,
 
}