
const Vals = {
    // LOCALHOST  : 'http://localhost:3001' , 
    LOCALHOST  : 'https://syphex.com' , 
    // API  : 'http://localhost:3000' , 
    API  : 'https://api.syphex.com/' , 
    // M_API  : 'http://localhost:3000' , 
    M_API  : 'https://api.syphex.com/' , 
    GET_HEADER : {  
        mode:'cors',
        method : 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    } ,

    POST_HEADER : {  
        mode:'cors',
        method : 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json'
        }
    } ,

    POST_UPLOAD_HEADER : {  
        mode:'cors',
        method : 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    } ,
}

export default Vals