import React, { useState } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import Router from './Router';
import { SideBar, TopBar ,Categorise ,SideNews, MobileTopBar} from '../components';
import { useData } from './Provider';
import { Login } from '../pages';
import Vals from './Vars';
import { useEffect } from 'react';
import { API } from './functions';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
 
const Main = () => {

    const { logged , setlogged ,user , setUser , socket , notifyNum , setNotifyNum ,setOnline } = useData()
    const [loading , setLoading ] = useState(true)
    const nav = useNavigate() 
    const [isTopBarFixed, setIsTopBarFixed] = useState(false);

    const handleScroll = () => {
      if (window.scrollY > 0 ) {
        setIsTopBarFixed(true);
      } else {
        setIsTopBarFixed(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const unseenNotifications = async () => {
        await API.get( `unseen-notifications` , Vals?.GET_HEADER)
        .then((res) => setNotifyNum(res?.data?.data) )
        .catch((e) => console.log(e))
    }

    const check = async () => {

        try {

            await API.get(`/checker/`, Vals?.GET_HEADER)
    
            .then(function (response) {

                if (response.data.res === false) {
                    setlogged(false) 
                    
                } 
        
                if (response.data.res === true) {
                    setlogged(true) 
                    setUser(response.data.w)
                    unseenNotifications()
                    socket?.on('connect', async () => {
                        socket?.emit('store socket', { user_id : response.data.w , socketId: socket.id });
                    })
                } 

                setLoading(false)

            })
      
        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {
        check()
        socket?.on('notification' , async (req) => {
            setNotifyNum(parseInt(req?.num))
        })
        socket?.on('num', (data) => { 
            setOnline(data?.count)
        })
    } , [])
 
    if (!loading)
    return( 
    
    <>
        <Box sx={{position: isTopBarFixed ? 'fixed' : 'static' ,  display:{xs:'none' , md : 'block'}  , width:'100%' , marginTop:isTopBarFixed?2:0 , zIndex:1}}>
            <TopBar  {...{nav}}/>
        </Box>

        <Box sx={{position: isTopBarFixed ? 'fixed' : 'static' ,  display:{xs:'block' , md : 'none'}  , width:'100%' , marginTop:isTopBarFixed?2:0 , zIndex:1}}>
            <MobileTopBar  {...{nav}}/>
        </Box>
            <Grid container spacing={2} p={1}>

            <Grid item lg={3} xl={3} md={3} xs={12} sm={12}  >
                <Box sx={{position: isTopBarFixed ? 'fixed' : 'static' , display:{xs:'none' , md : 'block'} , width:isTopBarFixed?'22%':'100%' , marginTop:isTopBarFixed?9:0}}>
                    <SideBar />
                </Box>
            </Grid>

            <Grid item lg={5} xl={5} md={5} xs={12} sm={12}>
                <Router />
            </Grid>

            <Grid item lg={4} xl={4} md={4} xs={12} sm={12} >
                <Box sx={{position: isTopBarFixed ? 'fixed' : 'static',  display:{xs:'none' , md : 'block'}  ,width:isTopBarFixed?'31%':'95%' , marginTop:isTopBarFixed?9:0,marginRight:isTopBarFixed?2:0}}>
                    <SideNews {...{nav}} />
                    <Categorise {...{nav}} />
                </Box>
            </Grid>

        </Grid>    
    
    
    </>

    
    
   )
  
}

export default Main;