export const Style = {
    Container : {
        borderColor:'primary.border',
        borderWidth:1,
        borderStyle :'solid',
        p:2,
        display:'flex',
        flexDirection:'column',
    } ,
    ChatContainer : {
        display:'flex',
        flexDirection:'column',
        borderColor:'gray',
        p:2,
        borderWidth:1,
        borderRadius:1,
        mt:1,
        height:450,
        overflow:'auto',
        borderStyle :'solid',
    } ,
    InputContainer : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
    } ,
    me : {
        backgroundColor:'primary.blue',
        float:'right',
        borderColor:'primary.border',
        borderWidth:1,
        borderStyle :'solid',
        p:1,
        borderRadius:'5px 5px 0px 5px',
        wordBreak:'break-word',
        maxWidth:'50%'

    } ,
    not : {
        backgroundColor:'primary.main',
        float:'left',
        borderColor:'primary.border',
        borderWidth:1,
        borderStyle :'solid',
        p:1,
        borderRadius:'5px 5px 5px 0px',
        wordBreak:'break-word',
        maxWidth:'50%'
    }
} 