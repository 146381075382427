export const Style = {
    Contsiner : {
        backgroundColor:'transparent',
        top:0,
        bottom:0,
        height:'100%',
        width:'100%',
        zIndex:1,
        // overflow: 'scroll'
    } ,

    List : {
        width: '100%',
        height:'auto',  
    }
    
}