export const Style = {
    Container : {
        width:'100%' ,
        p:2,
        float:'left',
        height:'auto',
    } ,
    InnerContainer : {
        borderWidth:0,
        borderTopWidth:1,
        borderStyle:'solid',
        float:'left',
        mt:1 ,
        mb:1 , 
        pt : 2
    } ,
    Tag : {
        borderRadius:15,
        pl:2,pr:2,pt:1,pb:1,mt:1,ml:1,
        float:'left',
        alignItem:'canter',
        display:'flex',
        cursor:'pointer'
    } ,
}