import React , {useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SendIcon from '@mui/icons-material/Send';
import { Box } from '@mui/system';
import { useData } from '../../config/Provider';
import { useNavigate } from 'react-router';
import { API, useLocalState } from '../../config/functions';
import Vals from '../../config/Vars';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import StarIcon from '@mui/icons-material/Star';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import LoginIcon from '@mui/icons-material/Login';
import GavelIcon from '@mui/icons-material/Gavel';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';


export default function MobileNav() {

  const nav = useNavigate()
  const { Color,setlogged,logged,user,setUser} = useData()
  const [ Islogged , setIslogged ] = useLocalState("Islogged" );
 
  const LeftbarItems =  [
    {
  
        title : '',
        
        list : [

            {
              title : 'Home',
              icon : <HomeTwoToneIcon sx={{color:'white'}} />,
              link : '/',
              dropdown : [ ]
            },
  
            {
              title : 'Popular',
              icon : <StarIcon sx={{color:'white'}} />,
              link : '/popular/',
              dropdown : [ ]
            },

            {
                title : 'Discussions',
                icon : <GroupWorkIcon  sx={{color:'white'}} />,
                link : '/discussions/',
                dropdown : [ ],
            },

            {
              title : 'Terms & Conditions',
              icon : <GavelIcon  sx={{color:'white'}} />,
              link : '/terms/',
              dropdown : [ ],
            },

            {
                title : 'Privacy Policy',
                icon : <ShieldTwoToneIcon  sx={{color:'white'}} />,
                link : '/privacy/',
                dropdown : [ ],
            },
  
        ],

        
    },
  
  ]

  const AccountLeftbarItems =  [
    {
  
        title : '',
        
        list : [
            {
              title : 'Home',
              icon : <HomeTwoToneIcon sx={{color:'white'}} />,
              link : '/',
              dropdown : [ ]
            },
            {
              title : 'Profile',
              icon : <AccountCircleIcon sx={{color:'white'}} />,
              link : `/profile/${user}`,
              dropdown : [ ]
            },
            
            {
              title : 'Popular',
              icon : <StarIcon sx={{color:'white'}} />,
              link : '/popular/',
              dropdown : [ ]
            },

            {
              title : 'Following',
              icon : <FileDownloadDoneIcon sx={{color:'white'}} />,
              link : '/following/',
              dropdown : [ ]
            },

            {
                title : 'Discussions',
                icon : <GroupWorkIcon  sx={{color:'white'}} />,
                link : '/discussions/',
                dropdown : [ ],
            },

            {
              title : 'Post',
              icon : <PostAddIcon  sx={{color:'white'}} />,
              link : '/add-post/',
              dropdown : [ ],
            },

            {
              title : 'Create Discussion',
              icon : <AddCommentIcon  sx={{color:'white'}} />,
              link : '/add-discussion/',
              dropdown : [ ],
            },

            {
              title : 'Terms & Conditions',
              icon : <GavelIcon  sx={{color:'white'}} />,
              link : '/terms/',
              dropdown : [ ],
            },

            {
                title : 'Privacy Policy',
                icon : <ShieldTwoToneIcon  sx={{color:'white'}} />,
                link : '/privacy/',
                dropdown : [ ],
            },

            {
                title : 'Logout',
                dropdown : [],
                icon : <LogoutIcon sx={{color:'white'}} />,
                link : '/logout/',
            },
  
        ],
    },
  
  ]
 
  const [ items , setItems ] = useState(LeftbarItems)

  useEffect(() => {
    setItems(logged?AccountLeftbarItems:LeftbarItems)
  } , [logged])

  const [isTopBarFixed, setIsTopBarFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 64) {
      setIsTopBarFixed(true);
    } else {
      setIsTopBarFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logout = async () => {

    try {

        await API.post(`/user-logout/`, Vals?.POST_HEADER)

        .then(function (response) {

          if (response?.data?.status === 'ok') {
              setlogged(false)
              setIslogged(false)
              setUser(null)
              nav('/')
          } else {
              console.log(response?.data?.msg)
          }
       
        })
  
    } catch (error) {

        console.log(error)

    }

  }
 

  return (

    <Box sx={{justifyContent:'center',display:'flex',alignItems:'center'}}>

      {items?.map((val, mainKey) =>  (
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'primary.main' }}
          component="nav"
          aria-labelledby="nested-list-subheader" 
        >
          { val.list.map((listVal, midKey) => (
            
            <ListItemButton  onClick={() => listVal.title === 'Logout' ? logout() : nav(listVal.link)}>
              <ListItemIcon>
                {listVal?.icon}
              </ListItemIcon>
              <ListItemText primary={listVal?.title} sx={{color:'primary.text'}} />
            </ListItemButton>

          ))}
    
        </List>
      ))}

    </Box>
  
  );
}