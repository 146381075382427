import { Alert, Button, CardMedia, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Style } from './Style'
import LoginIcon from '@mui/icons-material/Login';
import Vals from '../../config/Vars';
import { API, useLocalState } from '../../config/functions';
import { useState } from 'react';
import { useData } from '../../config/Provider';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
const Login = ({nav}) => {
    const {logged,setlogged ,setUser} = useData()
    const [ username , setusername ] = useState('')
    const [ password , setpassword ] = useState('')
    const [ Islogged , setIslogged ] = useLocalState("Islogged" , false);
    const [ alertStatus , setAlertStatus ] = useState(Boolean);
    const [ alertText , setAlertText ] = useState('');
    const [ lock , setLock ] = useState(false);
    const [ showAlert , setShowAlert ] = useState(false);
    document.title=`SYPHEX | Login` 

    const Submit = async () => {
        
        if (!username || !password ) {
            return false
        }

        await API.post( `/user-login/` , { username : username , password : password } , Vals?.POST_HEADER )
        .then( function ( response ) {
            console.log(response?.data)
            if (response?.data?.status === 'ok') {
                setLock(true)
                setTimeout(() => {
                    setlogged(true)
                    setIslogged(true)
                    nav('/')
                    setUser(response?.data?.user_id)
                }, 1500);
            } else {
                setShowAlert(true)
                setAlertStatus(false)
                setAlertText(response?.data?.msg)
                setlogged(false)
            }

            setTimeout(() => {
                setShowAlert(false)
            }, 7000);

        })
        .catch((e) => console.log(e) )
    }

    return (
        <Box sx={Style?.Container}>

            <Box sx={Style?.FormContainer}>

                {lock ? <LockOpenTwoToneIcon sx={{color:'white',fontSize:200,}} /> : <LockIcon sx={{color:'white',fontSize:200}} />}

                {showAlert && (
                    <Alert variant="outlined" severity={alertStatus?'success':'error'} sx={{width:'75%',color:'white',mb:2}}>
                        {alertText}
                    </Alert>
                )}
            
                <Box sx={Style?.Form}>
                    <TextField value={username} onChange={(e) => setusername(e.target.value)} InputLabelProps={{style:{ color: 'white' }}} sx={Style?.Input} label="Username" type="username" variant="outlined" autoComplete='new-password'/>
                    <TextField value={password} onChange={(e) => setpassword(e.target.value)} InputLabelProps={{style:{ color: 'white' }}} sx={Style?.Input} label="Password" type="Password" variant="outlined" autoComplete='new-password'/>
                </Box>
            
                <Box sx={Style?.Button}>
                    <Button onClick={Submit} sx={{width:330,backgroundColor:'primary.border'}} variant="contained" endIcon={<LoginIcon />}>Login</Button>
                </Box>

                <Box sx={Style?.Button}>
                    <Button onClick={() => nav('/register/')} sx={{width:330,backgroundColor:'primary.border'}} variant="contained" >New Account</Button>
                </Box>
            
            </Box>

        </Box>
    )
}

export default Login