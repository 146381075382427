import { Box, InputBase, Typography } from '@mui/material'
import React from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useEffect } from 'react'
import { useState } from 'react'
import Btn from '../Btn/Btn'
import TextInput from '../textInput/TextInput'
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const AffiliateData = ({info}) => {

    const [data, setData] = useState([])
    const [text, setText] = useState('Copy')
    const [paypal, setPaypal] = useState(info?.paypal)
    const [save, setSave] = useState('Save')

    useEffect(() => {
        API.get(`/affiliate-data/` , Vals?.GET_HEADER).then((res) => setData(res?.data?.data) ).catch((e) => console.log(e))
    } , [] )

    const Style = {
        Input : {
            input : {color:'primary.text' , },
            textarea : {color:'primary.text' , },
            borderWidth:1,
            color:'primary.text',
            borderStyle:'solid',
            borderColor:'primary.border',
            backgroundColor:'primary.main' , 
            '&:hover' : {  backgroundColor : 'primary.main'},
            width:'100%' ,
            padding:1,
            mt:2
        } ,
    }

    const copy = () => {
        navigator.clipboard.writeText(`${Vals?.LOCALHOST}/register/${info?.link}`)
        .then(() => {
            setText('Copied')
            setTimeout(() => {
                setText('copy')
            }, 3000);
        })
        .catch((error) => {
          console.error('Error copying text:', error);
        });
    }

    const savePaypal = () => {
        API.post(`/web-save-paypal/` , { paypal : paypal } ,  Vals?.POST_HEADER).then((res) => {
            
            if(res?.data?.status) {
                setSave('saved')
                setTimeout(() => {
                    setSave('save')
                }, 3000);
            }

        } ).catch((e) => console.log(e))
    }

  return (
    <Box sx={{wordBreak:'break-word'}}>
        <Typography color='primary.text' variant='h5'> Affiliate </Typography>
        <Box sx={{height:2,backgroundColor:'primary.blue',width:'30%'}} />

        <Box sx={{
            mt:2,
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            p:1
        }}>
            <Typography color='primary.text' variant='p'> Total Singed Users </Typography>
            <Box sx={{
                mt:2,
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                p:1
            }}>
            <GroupAddIcon sx={{color:'primary.text',fontSize:60}}/>
            
            <Typography color='primary.text' variant='p' sx={{fontSize:20}}> {info?.users?info?.users:0} </Typography>

            </Box>

        </Box>

        <Box sx={{
            display:'flex',
            flexDirection:'column',
            alignContent:'flex-start',
            p:1
        }}>
            <Typography color='primary.text' variant='p'> My Affiliate Link </Typography>
            <TextInput {...{value:`${Vals?.M_API}/register/${info?.link}`,placeholder:'LINK',type:'text',multiline:false,rows:1}}/>
            <Btn {...{title:text,func:copy}} />

        </Box>
        <Box mt={1}>
        </Box>

        <Box sx={{
            mt:2,
            display:'flex',
            flexDirection:'column',
            p:1
        }}>
            <Typography color='primary.text' variant='p'> Paypal Account </Typography>
            <TextInput {...{value:paypal,set:setPaypal,placeholder:'Paypal',type:'text',multiline:false,rows:1}}/>
            <Btn {...{title:save,func:savePaypal}} />
        </Box>

    </Box>
  )
}

export default AffiliateData