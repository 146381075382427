import { Avatar, Box, Grid, InputBase, Typography } from '@mui/material'
import React from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useEffect } from 'react'
import { useState } from 'react'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckIcon from '@mui/icons-material/Check';

const Achievements = ({info}) => {

    const [data, setData] = useState([])
    const [load, setLoad] = useState(true)
 

    const getData = async () => {
        API.get(`/user-achievements/` , Vals?.GET_HEADER).then((res) => {
            setData(res?.data)
            setLoad(false)
        }).catch((e) => console.log(e))
    }
    useEffect(() => {
        getData()
    } , [] )
 
    const types = [
        { title : 'Level 0' , color:'transparent' , type : 'none' , value : 0} ,
        { title : 'Level 1' , color:'#d0cccc' , type : 'Silver' , value : 1} ,
        { title : 'Level 2' , color:'#ceb99d' , type : 'Bronze' , value : 2} ,
        { title : 'Level 3' , color:'#c9ac2e' , type : 'Gold' , value : 3} ,
    ]

    const profilePic = data?.user_profile_pic ? `${Vals?.M_API}/profile-pictures/${data?.user_profile_pic}` :'/logo.png' 

    const achievementss = [
        {title : '+100 Posts' , check : data?.posts > 100 ? true : false } , 
        {title : '+100 Discussions members' , check : data?.users > 100 ? true : false} , 
        {title : '+100 Account followers' , check : data?.followers > 100 ? true : false} , 
    ]

    if (!load)
    return (
        <Box sx={{wordBreak:'break-word'}}>
            <Typography color='primary.text' variant='h5'> Achievements </Typography>
            <Box sx={{height:2,backgroundColor:'primary.blue',width:'30%'}} />

            <Box sx={{
                mt:2,
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                p:1
            }}>
                <Typography color='primary.text' variant='p'> Account levels </Typography>
                <Grid container mt={5}>
                    {types?.map((item,key) =>(
                        <Grid item={true} key={key} xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Box sx={{display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center'}}>
                                <Avatar sx={{ 
                                    height:{xs:50,md:90},
                                    width:{xs:50,md:90},
                                    borderWidth:2,
                                    borderColor:item?.color,
                                    borderStyle:'solid',
                                    borderRadius:100
                                }} src={profilePic} />
                                <Typography color='primary.text' fontSize={12} mt={2}> {item?.type} </Typography>
                                <Typography color='primary.text' mt={0}> {item?.title} </Typography>
                                <Typography color='primary.text' mt={0}> {item?.value == data?.level && <CheckIcon sx={{color:'primary.text'}} />} </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>

            </Box>

            <Box mt={4}>
                <Typography color='primary.text' fontSize={20}>In order to increase your account level you have to achieve :</Typography>
                <Box mt={2}>
                    {achievementss?.map((item,key) => (
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start'}} key={key}>
                            <Typography sx={{lineHeight:2}} color='primary.text'   > {item?.title}</Typography>
                            {item?.check ? <CheckBoxIcon sx={{color:'primary.text',ml:1}} /> : <CheckBoxOutlineBlankIcon sx={{color:'primary.text',ml:1}} />}
                        </Box>
                    ))}
                </Box>
            </Box>

        </Box>
    )
}

export default Achievements