import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Btn from '../Btn/Btn'

const PageHeader = ({title,btnTitle,func}) => {
    const Style = {
        Container : {
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            pb:3,
            pt:3,
            alignItems:'center'
        }
    }
  return (
    <Box sx={Style?.Container}>
        <Typography sx={{color:'primary.text',}} variant='h6'>{title}</Typography>
        {btnTitle && (
          <Btn {...{title:btnTitle,func:func}}/>
        )}
    </Box>
  )
}

export default PageHeader