export const Style = {
    Container : {
        display : 'flex' ,
        flexDirection : 'row' , 
        justifyContent : 'space-between' ,
        alignItems : 'center' ,
        height:70 ,
        padding:3,
     
    } ,

    InnerContainer : {
        display : 'flex' ,
        flexDirection : 'row' , 
        alignItems : 'center' ,
    } ,

    Button : {
        marginRight:5 ,
        marginLeft:5 ,
        cursor:'pointer'
    } ,

    centerBtns : {
        borderStyle:'solid' , 
        borderColor:'red',
        brderWidth:1
    } ,

    Logo : {
        marginRight:5 ,
        marginLeft:5 ,
        height:55,
        width:55
    } ,

    SearchInput : {
        height:30 ,
        borderRadius : 5 ,
        borderWidth : 1 ,
        borderColor : 1 ,
    }
}