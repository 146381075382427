import { Box, ThemeProvider } from "@mui/material";
import React ,{ createContext, useState, useContext, useEffect } from "react";
import { Light , Dark } from "../style/ThemeStyle";
import { useLocalState } from "./functions";
import axios from "axios";
import { io } from "socket.io-client";
axios.defaults.withCredentials = true


export const Prv = createContext();

export const useData = () => {
    return useContext(Prv);
}
 
const Provider = ({children}) => {
    
    const [ load , setLoad ] = useState(true);
    const [ logged , setlogged ] = useState(false);
    const [ user , setUser ] = useState(null);
    const [ userData , setUserData ] = useState([]);
    const [ Islogged , setIslogged ] = useLocalState("Islogged" );
    const [online , setOnline] = useState(0)
    const [notifyNum , setNotifyNum] = useState(0)
    const [discussionNum , setdiscussionNum] = useState(0)
    const [socket, setSocket] = useState(null)
    const [socketError, setSocketError] = useState(null);

    // useEffect(() => {
    //     const SK = io('https://api.syphex.com')
    //     // const SK = io('http://localhost:3000')
    //     setSocket(SK)
    //     setLoad(false)
    //     return () => SK?.disconnect()
    // } , [user])

    useEffect(() => {
        const setupWebSocket = () => {
          try {
        const SK = io('https://api.syphex.com')
        // const SK = io("http://localhost:3000");
            setSocket(SK);
          } catch (error) {
            console.error("WebSocket connection failed:", error);
            setSocketError(error.message);
          } finally {
            setLoad(false);
          }
        };
    
        setupWebSocket();
    
        return () => {
          if (socket) {
            socket.disconnect();
          }
        };
      }, [user]);
    
      if (socketError) {
        // If there's a WebSocket connection error, display an error message.
        return <div>WebSocket connection failed: {socketError}</div>;
      }

    if (!load)
    return (
        <Prv.Provider value={{
                userData : userData , 
                setUserData : setUserData ,
                discussionNum : discussionNum , 
                setdiscussionNum : setdiscussionNum ,
                notifyNum : notifyNum , 
                setNotifyNum : setNotifyNum ,
                online : online , 
                setOnline : setOnline ,
                socket:socket ,
                user : user , 
                setUser : setUser ,
                logged : logged, 
                setlogged : setlogged ,
            }} >
            <ThemeProvider theme={Dark}> 
                {children}
            </ThemeProvider>
        </Prv.Provider>
    );

}

export default Provider;

