import { Button } from '@mui/material'
import React from 'react'

const Btn = ({title,func}) => {
  
  const Style = {
    color:'primary.text' ,
    backgroundColor:'primary.bg' ,
    '&:hover':{ backgroundColor:'primary.black' },
    borderColor:'gray',
    borderWidth:1,
    borderStyle:'solid',
    fontSize:{xs:10,md:13}
  }

  return (
    <Button sx={Style} onClick={func}> {title} </Button>
  )
}

export default Btn