import './App.css';
import { BrowserRouter, HashRouter } from 'react-router-dom'
import Provider from './config/Provider'
import Main from './config/Main'
import { useLocalState } from './config/functions';
import { SnackbarProvider } from 'notistack';

function App() { 
  const [ Islogged , setIslogged ] = useLocalState("Islogged" , false );

  return (
    <BrowserRouter> 
      <Provider>
        <SnackbarProvider >
          <Main/>
        </SnackbarProvider>
      </Provider>
    </BrowserRouter > 
  );
}

export default App;
