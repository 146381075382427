import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState , useContext , useEffect} from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { Style } from './Style'

const Categorise = ({nav}) => {
    
    const [data , setData ] = useState([])

    const getData = async () => {
        await API.get(`/categorise/` , Vals?.GET_HEADER )
        .then( ( response ) => {
            setData( response?.data?.data )
        })
        .catch((e) => console.log(e) )
    }

    useEffect(() => {
        getData()
    } , [])

    const Category = ({item}) => (
        <Box sx={[Style?.Tag , { backgroundColor:'primary.black' }]} onClick={() => nav(`/news/${item?.category_id}`)}>
            <Typography color='primary.text'>{item?.category_title}</Typography>
        </Box>
    )

    return (
        <Box sx={[Style?.Container , { backgroundColor:'primary.main' , mt:1 }]}>
            <Typography variant='h6' color='primary.text'> Trending </Typography>
            <Box sx={[Style?.InnerContainer , { borderColor:'primary.border' }]}>
                {data?.map((item,k) => ( <Category key={k} {...{item}} /> ))}
            </Box>
        </Box>
    )
}

export default Categorise