import { Avatar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Vals from '../../config/Vars'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const SelectDiscussion = ({item,set,val}) => {
    const Style = {
        Container : {
            width:'95%',
            borderStyle:'solid',
            borderWidth:1,
            borderColor:val == item?.discussion_id ? 'white' :'primary.border' ,
            p:2,
            justifyContent:'flex-start',
            alignItems:'center',
            display:'flex',
            flexDirection:'row',
            cursor : 'pointer',
        },
        info:{
            justifyContent:'flex-start',
            display:'flex',
            flexDirection:'column' ,
            ml:2
        },
        profilePic : { 
            height:100,
            width:100,
        } , 
        iconContainer : {
            justifyContent:'flex-start',
            alignItems:'center',
            display:'flex',
            flexDirection:'row' ,
        }
    }

    
    const img = item?.discussion_profile_pic ? `${Vals?.M_API}/profile-pictures/${item?.discussion_profile_pic}` : '/logo.png'
  return (
    <Box sx={Style?.Container} onClick={() => set(item?.discussion_id)}>
        <Avatar src={img}  sx={Style?.profilePic} />
        <Box sx={Style?.info}>
            <Typography color='primary.text' fontSize={25}> {item?.discussion_title} </Typography>
            <Box sx={Style?.iconContainer}>
                <PeopleAltIcon sx={{color:'primary.text' , mr:1 , fontSize:20}}/>
                <Typography color='primary.text'> {item?.members} </Typography>
            </Box>
        </Box>
    </Box>
  )
}

export default SelectDiscussion