import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useParams } from 'react-router'
import { Box, Typography } from '@mui/material'

const AffiliateTerms = ({setSection}) => {

    const [data, setData] = useState([])

    useEffect(() => {
        API.get(`/affiliate-terms/` , Vals?.GET_HEADER).then((res) => setData(res?.data?.data) ).catch((e) => console.log(e))
    } , [] )

    const Accept = async () => {
        await API.post(`/accept-affiliate-terms/` , Vals?.POST_HEADER)
        .then((res) => {
            setSection( res?.data?.status )
        }).catch((e) => console.log(e))
    }

    const Style = {
        Cover : {
            width:'100%',
            border:'1px solid gray',
            height:250,
            borderRadius:1,
            mt:2,
            mb:2,
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'center',
            backgroundSize:'cover',
            backgroundRepeat:'no-repeat',
            backgroundPosition:'center',
        } , 
        acceptBtn : {
            height:40,
            width:250,
            borderRadius:1,
            backgroundColor:'primary.main',
            borderWidth:1,
            borderColor:'gray',
            borderStyle:'solid',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'center',
            mt:10,
            cursor:'pointer'
        }
    }

    return (
        <Box>
            <Typography color='primary.text' variant='h1' fontSize={40}> Terms & Conditions of Affiliates </Typography>
            <Box sx={{height:2,backgroundColor:'primary.blue',width:'30%'}} />
            <div style={{color:'white',marginTop:10}} dangerouslySetInnerHTML={{ __html: data }}></div>
            <Box sx={Style?.acceptBtn} onClick={Accept}>
                <Typography color='primary.text'> Accept </Typography>
            </Box>
            <Box sx={{height:200,width:'100%'}} />
        </Box>
    )
}

export default AffiliateTerms