export const Style = {
    Container : {
        display:'flex',
        justifyContent:'center',
        alignItems:'center' ,
        flexDirection : 'column',
        height:'100%'
    } ,
    FormContainer : {
        display:'flex',
        alignItems:'center' ,
        width:'50%',
        justifyContent:'space-between',
        padding:5,
        // backgroundColor:'primary.main',
        flexDirection : 'column',
        // borderStyle:'solid',
        // borderColor:'primary.border',
        // borderWidth:1,
        // borderRadius:1
    } ,
    img : {
        width:150,
        mb:5,
        borderRadius:50
    } ,
    Form : {
        justifyContent:'center',
        display:'flex',
        flexDirection : 'column',
        alignItems:'center' ,
    } ,
    Input : {
        input : {color:'primary.text' ,width:300 },
        borderWidth:1,
        borderColor:'primary.border',
        backgroundColor:'primary.main' , 
        '&:hover' : {  backgroundColor : 'primary.border'},
        color : 'white' ,
        width:'100%' ,
        borderStyle:'solid',
        mt:2,
        borderRadius:1,
        zIndex:0
    } ,
    Button : {
        mt:5 ,
        display:'flex',
        justifyContent:'flex-start',
        flexDirection : 'row',
    }
}