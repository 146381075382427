import React from 'react'
import { InputBase  } from '@mui/material'

const TextInput = ({value,set,placeholder,type,multiline,rows}) => {

    const Style = {
        Input : {
            input : {color:'primary.text' , },
            textarea : {color:'primary.text' , },
            borderWidth:1,
            borderStyle:'solid',
            borderColor:'primary.border',
            backgroundColor:'primary.main' , 
            '&:hover' : {  backgroundColor : 'primary.main'},
            width:'100%' ,
            padding:1,
        } , 
    }
    
    return (
        <InputBase type={type} value={value} rows={rows?rows:0} multiline={multiline?multiline:false}  onChange={(e) => set(e.target.value)} placeholder={placeholder} sx={Style?.Input} variant="outlined" autoComplete='off'/>
    )
}

export default TextInput