import { AppBar, IconButton, Menu, Badge, MenuItem, Grid, Avatar, FormControl, OutlinedInput, InputBase, CardMedia, Tooltip, Button, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import { Style } from './Style';
import { Box } from '@mui/system';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import Groups3Icon from '@mui/icons-material/Groups3';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Prv, useData } from '../../config/Provider';
import Btn from '../Btn/Btn';
import { API, useLocalState } from '../../config/functions';
import Vals from '../../config/Vars';

const TopBar = ({nav}) => {

  const [ Islogged , setIslogged ] = useLocalState("Islogged" );
  const [ search , setSearch ] = useState("");

  const { Color , logged , setlogged , notifyNum} = useData()

  const handleSubmit = (e) => {
    e.preventDefault();
    nav(`/search/${search}`)
  };

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };
 
  return (
    <AppBar position="static" sx={[Style?.Container , { backgroundColor:Color?.main }]}>

        <Grid container >
            <Grid item lg={4} xl={4} sx={[Style?.InnerContainer , { justifyContent : 'flex-start' } ]}>
               
                <Avatar sx={Style?.Logo} alt="SYPHEX" src="/logo.png" onClick={()=> nav('/')} >B</Avatar>
    
                <FormControl sx={{ width: '100%'  }}>
                <InputBase   value={search}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}  
                    sx={{ borderRadius:3 , p:1 , pl:2  , ml: 1, flex: 1 , color: 'primary.text' , backgroundColor:'primary.bg' , borderWidth : 2 , borderColor:'primary.border'}}
                    placeholder="Search"
                />
                </FormControl>

            </Grid>
            
            <Grid item lg={4} xl={4} sx={[Style?.InnerContainer , { justifyContent : 'center' } ]}>

                <Tooltip disableFocusListener disableTouchListener title="Home" onClick={() => nav('/')}>
                        <HomeRoundedIcon  sx={[Style?.Button , {color : Color?.blue}]}/>
                </Tooltip>
                <Tooltip   disableFocusListener disableTouchListener title="Discussions"  onClick={() => nav('/discussions/')}>
                        <Groups3Icon sx={[Style?.Button , {color : Color?.text}]} />
                </Tooltip>
                <Tooltip   disableFocusListener disableTouchListener title="News"  onClick={() => nav('/news/')}>
                        <LibraryBooksIcon  sx={[Style?.Button , {color : Color?.text}]}/>
                </Tooltip>

            </Grid>

            <Grid item lg={4} xl={4} sx={[Style?.InnerContainer , { justifyContent : 'flex-end' } ]}>

                {logged && (
                  <Badge sx={[Style?.Button , {color : Color?.text}]} onClick={() => nav('/notifications/')} badgeContent={notifyNum}>
                    <NotificationsIcon />
                  </Badge> 
                )}
                
                {logged && (
                  <Badge sx={{mr:4}}  onClick={() => nav('/chat/')}>
                    <ChatIcon />
                  </Badge> 
                )}
                
                {!logged && (
                  <Btn {...{title:'Login' , func : () => nav('/login/') }}/>
                )}

                <Typography pr={2} pl={2} sx={{fontSize:20,display:{xs:'none',md:'flex'}}}>SYPHEX</Typography>
                
            </Grid>
        </Grid>
    </AppBar>
  );
};

export default TopBar