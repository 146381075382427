import { Avatar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Vals from '../../config/Vars'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { CalculateTime } from '../../config/functions';

const NotificationsCard = ({item,nav}) => {

    const CommentText = () => {
        if ( item?.n_type === 'comment') {
            return `commented on your post "${item?.post_title?.substring(0,20)}..."`
        }
    }

    const Style = {
        Container : {
            width:{sx:'90%',md:'95%'},
            borderStyle:'solid',
            borderWidth:1,
            borderColor: 'primary.border'   ,
            p:2,
            justifyContent:'flex-start',
            alignItems:'center',
            display:'flex',
            flexDirection:'row',
            cursor : 'pointer',
        },
        info:{
            justifyContent:'flex-start',
            display:'flex',
            flexDirection:'column' ,
            ml:2
        },
        profilePic : { 
            height:100,
            width:100,
        } , 
        iconContainer : {
            justifyContent:'flex-start',
            alignItems:'center',
            display:'flex',
            flexDirection:'row' ,
        }
    }

    const visit = () => {
        nav(`/post/${item?.n_content_id}`)
    }

    const img = item?.user_profile_pic ? `${Vals?.M_API}/profile-pictures/${item?.user_profile_pic}` : '/logo.png'

    return (
        <Box sx={Style?.Container} onClick={visit}>
            <Avatar src={img}  sx={Style?.profilePic} />
            <Box sx={Style?.info}>
                <Typography color='primary.text' fontSize={20}> @{item.user_username} </Typography>
                <Typography color='primary.text'> {CommentText()} </Typography>
                <Typography color='primary.text'> {CalculateTime(item?.n_date)} </Typography>
            </Box>
        </Box>
    )
}

export default NotificationsCard