import { Avatar, Box, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Autoplay, Pagination, Scrollbar } from 'swiper';
import 'swiper/swiper.css';

import 'swiper/css/pagination';
  
import { Prv, useData } from '../../config/Provider';
import Vals from "../../config/Vars";

const Slider = ({media}) => {

    const {lang} = useData()
    const [data, setData] = useState([])
    SwiperCore.use([Autoplay]);

    const Style = {
      container : {
        width:'100%',height:300,zIndex:1,
        alignItems: "center",
        flexDirection: "column",
        display:'flex'
     
      },
      Border : { 
        borderWidth:1,
        borderColor:'lightgray',
        borderStyle:'solid',
        width:'90%',
        height:'85%',
        padding: 2 ,
        backgroundColor:'primary.light',        
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between'
      } ,
    
        UserContainer : {
          display:'flex',
          mb:2,
          flexDirection:'row',
        },
        avatar: {
          width: 60,
          height: 60,
          mr:3,
          ml:3
        },
        des : { 
          width:'100%'
        } ,
        commentContainer: {
            height:'100%',
          alignItems: "center",
          flexDirection: "column",
          mt: 5,
          width:'100%'
        },
      }

      useEffect(() => {
        setTimeout(() => {
          setData(JSON.parse(media))
        }, 1000);
      } , [])

    return (
       <Swiper 
            slidesPerView={1}
            modules={[Scrollbar, A11y,Pagination ]}
            loop={true}
            navigation
            pagination={{ clickable: true }}
            // autoplay={{delay: 5000,disableOnInteraction: false,}}
            style={{ height : '100%' , width : '100%' , zIndex:0  }}>
            {data?.map((item,key) => (
                <SwiperSlide  key={key}>
                    <Box sx={Style?.container}>
                    {item?.type === 'image' && (
                      <img style={{objectFit:'cover',height:'100%'}} src={`${Vals?.M_API}/posts/${item?.name}`} />
                    )}
                    {item?.type === 'video' && (
                      <video controls style={{objectFit:'cover',height:'100%'}}>
                        <source src={`${Vals?.M_API}/posts/${item?.name}`} type="video/mp4" />
                      </video>
                    )}
                    </Box> 
                </SwiperSlide>
            ))}

        </Swiper>
    );
};

export default Slider;