import { Grid, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useData } from '../../config/Provider'
import { DiscussionsCard, Empty, PostCard, SearchResultCard } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useParams } from 'react-router'


export default function Search({nav}) {

  const [ data , setData ] = useState([])
  const [ page , setPage ] = useState(0)
  const {word} = useParams()
  document.title=`SYPHEX | Search for '${word}' ` 
  const getData = async () => {
    await API.get(`/search/${word}` , Vals?.GET_HEADER).then((res) => setData(res?.data?.data) ).catch((e) => console.log(e))
  }
  useEffect(() => {
    getData()
  } , [word])

  return  data?.length > 0 ? data?.map( ( item , key) => <SearchResultCard key={key} {...{nav,item}} /> ) :  <Empty {...{text:'no results found'}} />
 
}
