import { Alert, Button, CardMedia, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { Style } from './Style'
import LoginIcon from '@mui/icons-material/Login';
import Vals from '../../config/Vars';
import { API, useLocalState } from '../../config/functions';
import { useState } from 'react';
import { useData } from '../../config/Provider';
import SensorOccupiedTwoToneIcon from '@mui/icons-material/SensorOccupiedTwoTone';
import { useParams } from 'react-router';
const AffiliateRegister = ({nav}) => {
    const {logged,setlogged ,setUser} = useData()
    const { link } = useParams()
    const [ username , setusername ] = useState('')
    const [ password , setpassword ] = useState('')
    const [ cpassword , setcpassword ] = useState('')
    const [ Islogged , setIslogged ] = useLocalState("Islogged" , false);
    const [ alertStatus , setAlertStatus ] = useState(Boolean);
    const [ alertText , setAlertText ] = useState('');
    const [ showAlert , setShowAlert ] = useState(false);
    const [ checkUsername , setCheckUsername ] = useState(false);
    const [ check , setCheck ] = useState(false);

    document.title=`SYPHEX | Register` 

    useEffect(() => {
        API.get(`/web-register-check-affiliate/${link}` , Vals?.GET_HEADER).then((res) => setCheck(res?.data?.check) ).catch((e) => console.log(e))
    } , [] )

    const Submit = async () => {

        if (username?.length < 5) {
            setAlertText('Username must be greater than 5 characters')
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
            }, 7000);
        }
        
        if (!username || !password  ) {
            return false
        }

        if (cpassword !== password ) {
            setShowAlert(true)
            setAlertStatus(false)
            setAlertText('Passwords not matched !')
            setTimeout(() => {
                setShowAlert(false)
            }, 3000);
            return false
        }

        await API.post( `/signup-affiliate/` , { username : username , password : password , link : link } , Vals?.POST_HEADER )
        .then( function ( response ) {
            if (response?.data?.status === true) {
                setAlertStatus(true)
                setAlertText('Account created successfully')
                setTimeout(() => {
                    nav('/login/')
                }, 3000);
            } else {
                setAlertStatus(false)
                setAlertText(response?.data?.msg)
            }
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
            }, 7000);
        })
        .catch((e) => console.log(e) )
    }

    useEffect(   () => {
        if (username?.length > 5) {
            API.post( `/check-username/` , { username : username } , Vals?.POST_HEADER )
            .then( function ( response ) {
                setCheckUsername(response?.data?.status)
            })
            .catch((e) => console.log(e) )
        } else {
            setCheckUsername(false)
        }

    } , [username?.length])

    if (check)
    return (
        <Box sx={Style?.Container}>

            <Box sx={Style?.FormContainer}>
            
                 <SensorOccupiedTwoToneIcon sx={{color:'white',fontSize:200,mb:3}}/>

                {showAlert && (
                    <Alert variant="outlined" severity={alertStatus?'success':'error'} sx={{width:'75%',color:'white',mb:2}}>
                        {alertText}
                    </Alert>
                )}
               
                <Box sx={Style?.Form}>
                    <TextField value={username} onChange={(e) => setusername(e.target.value)} InputLabelProps={{style:{ color: 'white' }}} sx={[Style?.Input,{borderColor:username?.length > 5 ? checkUsername?'green':'red' : 'primary.border'}]} label="Username" type="username" variant="outlined" autoComplete="new-password"/>
                    <TextField value={password} onChange={(e) => setpassword(e.target.value)} InputLabelProps={{style:{ color: 'white' }}} sx={Style?.Input} label="Password" type="Password" variant="outlined" autoComplete="new-password"/>
                    <TextField value={cpassword} onChange={(e) => setcpassword(e.target.value)} InputLabelProps={{style:{ color: 'white' }}} sx={Style?.Input} label="Confirm password" type="Password" variant="outlined" autoComplete="new-password"/>
                </Box>
            
                <Box sx={Style?.Button}>
                    <Button onClick={alertStatus?console.log('loading'):Submit} sx={{width:330,backgroundColor:'primary.border'}} variant="contained" endIcon={<LoginIcon />}>Create</Button>
                </Box>

                <Box sx={Style?.Button}>
                    <Button onClick={() => nav('/login/')} sx={{width:330,backgroundColor:'primary.border'}} variant="contained" >Login</Button>
                </Box>
            
            </Box>

        </Box>
    )
}

export default AffiliateRegister