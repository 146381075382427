import { Grid, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useData } from '../../config/Provider'
import { Empty, NotificationsCard } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'


export default function Notifications({nav}) {
  document.title=`SYPHEX | Notifications` 

  const [ data , setData ] = useState([])
  const [ page , setPage ] = useState(0)
  const { socket , setNotifyNum , notifyNum } = useData()
  
  useEffect(() => {
    socket?.on('NewNotify', (Notification) => {
        setData(...data , Notification)
        console.log(Notification)
    })
  }, []) 

  const getData =  async () => {
    await API.get( `/notifications/` , Vals?.GET_HEADER )
    .then( ( response ) => {
        setData(response?.data?.data)
    })
    .catch((e) => console.log(e) )
  }
  
  useEffect(() => {
    getData()
    setNotifyNum(0)
  } , [notifyNum])

  return data?.length > 0 ? data?.map( ( item , key) => <NotificationsCard key={key} {...{nav,item}} /> ) :  <Empty {...{text:'You have no notifications'}} />
 
}
