export const Style = {

    Label  : {
        display:'flex' , 
        width:'25%' , 
        height:'100%' , 
        justifyContent:'center' , 
        flexDirection:'column' , 
        alignItems:'center',
    } ,

    inputItem: {height:'auto' , width:'100%', display:'flex' , flexDirection:'row' , alignItems:'center' , justifyContent:'space-between',borderWidth: 0.5 ,mb:2, BorderColor:'peimary.border',borderStyle:'solid' },

    input : {height:'auto' , width:'75%' , flexDirection:'column' , display:'flex', justifyContent:'flex-start' ,alignItems:'flex-start' , pb:2, pt:2,pr:2 },

    cover : {
        borderWidth: 0.2,
        borderStyle: `solid`,
        width:'100%',
        height:200,
        borderColor: `gray`,
        borderRadius:3 ,
    },

    tagsCon : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center',
        pt:2,
        pb:2,
    },

    tag : {
        float:'left',pr:2,pl:2,pt:1,pb:1,borderRadius:4,
        borderStyle:'solid',
        borderWidth:1,
    }
 
}