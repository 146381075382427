import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import {  BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate  } from "react-router-dom";
import {   AccountSettings, Achievements, Activate, AddDiscussion, AddPost, Affiliate, AffiliateRegister, Blog, CategoryNews, Chat, Discussion, Discussions, DiscussionSettings, Following, Home, Login, MobileNav, Notifications, Popular, Post, Privacy, Profile, Register, Search, Support    } from '../pages/'
import News from "../pages/news";
import { API, useLocalState } from "./functions";
import { useData } from "./Provider";
import Vals from "./Vars";
import Terms from "../pages/terms/Terms";
// axios.defaults.withCredentials = true
 
const Router = () => {

    const nav = useNavigate() 
    const { logged,setlogged,user } = useData()
    const [  load, setLoad ] = useState(true)
    const [  items, setItems ] = useState([])
    const [ Islogged , setIslogged ] = useLocalState("Islogged" )

    const GUEST = [
        {path:'/',element:<Home {...{nav}} />},
        {path:'/profile/:id',element:<Profile {...{nav}} />},
        {path:'/discussion/:id',element:<Discussion {...{nav}} />},
        {path:'/discussions/',element:<Discussions {...{nav}} />},
        {path:'/popular/', element:<Popular {...{nav}} />},
        {path:'/news/:id', element:<CategoryNews {...{nav}} />},
        {path:'/news/', element:<News {...{nav}} />},
        {path:'/post/:id', element:<Post {...{nav}} />},
        {path:'/login/', element:<Login {...{nav}} />},
        {path:'/register/', element:<Register {...{nav}} />},
        {path:'/register/:link', element:<AffiliateRegister {...{nav}} />},
        {path:'/activate/:token', element:<Activate {...{nav}} />},
        {path:'/mobile-nav/', element:<MobileNav {...{nav}} />},
        {path:'/search/:word', element:<Search {...{nav}} />},
        {path:'/blog/:id', element:<Blog {...{nav}} />},
        {path:'/terms/', element:<Terms {...{nav}} />},
        {path:'/Privacy/', element:<Privacy {...{nav}} />},
        {path:'/Support/', element:<Support {...{nav}} />},
    ]
    
    const ACCOUNT  = [
        {path:'/',element:<Home {...{nav}} />},
        {path:'/profile/:id',element:<Profile {...{nav}} />},
        {path:'/discussion/:id',element:<Discussion {...{nav}} />},
        {path:'/discussions/',element:<Discussions {...{nav}} />},
        {path:'/popular/', element:<Popular {...{nav}} />},
        {path:'/news/:id', element:<CategoryNews {...{nav}} />},
        {path:'/news/', element:<News {...{nav}} />},
        {path:'/post/:id', element:<Post {...{nav}} />},
        {path:'/login/', element:<Login {...{nav}} />},
        {path:'/add-post/', element:<AddPost {...{nav}} />},
        {path:'/settings/', element:<AccountSettings {...{nav}} />},
        {path:'/discussion-settings/:id', element:<DiscussionSettings {...{nav}} />},
        {path:'/add-discussion/', element:<AddDiscussion {...{nav}} />},
        {path:'/chat/', element:<Chat {...{nav}} />},
        {path:'/notifications/', element:<Notifications {...{nav}} />},
        {path:'/search/:word', element:<Search {...{nav}} />},
        {path:'/mobile-nav/', element:<MobileNav {...{nav}} />},
        {path:'/blog/:id', element:<Blog {...{nav}} />},
        {path:'/terms/', element:<Terms {...{nav}} />},
        {path:'/following/', element:<Following {...{nav}} />},
        {path:'/Privacy/', element:<Privacy {...{nav}} />},
        {path:'/affiliate/', element:<Affiliate {...{nav}} />},
        {path:'/achievements/', element:<Achievements {...{nav}} />},
        {path:'/Support/', element:<Support {...{nav}} />},
    ]

    useEffect(() => {
        setItems(logged?ACCOUNT:GUEST)
        setLoad(false)
    } , [user,logged])

    if (!load)
    return   (
        <Routes>   
            {items?.map((item,key) => <Route key={key} path={item?.path} element={item?.element} /> )}
        </Routes>
    )  
 
};

export default Router;