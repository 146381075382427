import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useParams } from 'react-router'
import { Box, Typography } from '@mui/material'

const Blog = () => {

    const [data, setData] = useState([])
    const {id} = useParams()

    useEffect(() => {
        API.get(`/blog/${id}` , Vals?.GET_HEADER).then((res) => setData(res?.data?.data) ).catch((e) => console.log(e))
    } , [id])

    const Style = {
        Cover : {
            width:'100%',
            border:'1px solid gray',
            height:250,
            borderRadius:1,
            mt:2,
            mb:2,
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'center',
            backgroundSize:'cover',
            backgroundRepeat:'no-repeat',
            backgroundPosition:'center',
        }
    }

    return (
        <Box>
            <Typography color='primary.text' variant='h1' fontSize={40}> {data?.blog_title} </Typography>
            <Box sx={{height:2,backgroundColor:'primary.blue',width:'30%'}} />
            {data?.blog_cover && (
                <Box sx={[Style?.Cover,{backgroundImage : `url(${Vals?.M_API}/blogs/${data?.blog_cover?data?.blog_cover:'empty.png'})`}]}/>
            )}
            <Typography color='primary.text' variant='h3' fontSize={15} mt={1}> 
                {new Date(data?.blog_date).toLocaleString()} 
            </Typography>
            <div style={{color:'white'}} dangerouslySetInnerHTML={{ __html: data?.blog_description }}></div>
        </Box>
    )
}

export default Blog