import { React, useEffect, useState  }  from 'react'
import { List, ListItemIcon, ListItemText, ListItemButton , Box  } from '@mui/material';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import PersonIcon from '@mui/icons-material/Person';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import StarIcon from '@mui/icons-material/Star';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useNavigate } from "react-router-dom";
import { Style } from './Style';
import Vals from '../../config/Vars'
import { useData } from '../../config/Provider';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { API, useLocalState } from '../../config/functions';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import GavelIcon from '@mui/icons-material/Gavel';
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import OfflineShareTwoToneIcon from '@mui/icons-material/OfflineShareTwoTone';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const SideBar = ({}) => {
  const nav = useNavigate()
  const { Color,setlogged,logged,user,setUser} = useData()
  const [ Islogged , setIslogged ] = useLocalState("Islogged" );
 
  const LeftbarItems =  [
    {
  
        title : '',
        
        list : [

            {
              title : 'Home',
              icon : <HomeTwoToneIcon sx={{color:Color?.text}} />,
              link : '/',
              dropdown : [ ]
            },
  
            {
              title : 'Popular',
              icon : <StarIcon sx={{color:Color?.text}} />,
              link : '/popular/',
              dropdown : [ ]
            },

            {
                title : 'Discussions',
                icon : <GroupWorkIcon  sx={{color:Color?.text}} />,
                link : '/discussions/',
                dropdown : [ ],
            },

            {
              title : 'Terms & Conditions',
              icon : <GavelIcon  sx={{color:Color?.text}} />,
              link : '/terms/',
              dropdown : [ ],
            },

            {
                title : 'Privacy Policy',
                icon : <ShieldTwoToneIcon  sx={{color:Color?.text}} />,
                link : '/privacy/',
                dropdown : [ ],
            },

            {
              title : 'Support',
              icon : <SupportAgentIcon  sx={{color:Color?.text}} />,
              link : '/support/',
              dropdown : [ ],
            },
  
        ],

        
    },
  
  ]

  const AccountLeftbarItems =  [
    {
  
        title : '',
        
        list : [
            
            {
              title : 'Home',
              icon : <HomeTwoToneIcon sx={{color:Color?.text}} />,
              link : '/',
              dropdown : [ ]
            },

            {
              title : 'Profile',
              icon : <AccountCircleIcon sx={{color:Color?.text}} />,
              link : `/profile/${user}`,
              dropdown : [ ]
            },
            
            {
              title : 'Popular',
              icon : <StarIcon sx={{color:Color?.text}} />,
              link : '/popular/',
              dropdown : [ ]
            },

            {
              title : 'Following',
              icon : <FileDownloadDoneIcon sx={{color:Color?.text}} />,
              link : '/following/',
              dropdown : [ ]
            },

            {
              title : 'Achievements',
              icon : <AutoGraphIcon sx={{color:Color?.text}} />,
              link : '/achievements/',
              dropdown : [ ]
            },

            {
              title : 'Discussions',
              icon : <GroupWorkIcon  sx={{color:Color?.text}} />,
              link : '/discussions/',
              dropdown : [ ],
            },

            {
              title : 'Affiliate',
              icon : <OfflineShareTwoToneIcon  sx={{color:Color?.text}} />,
              link : '/affiliate/',
              dropdown : [ ],
            },

            {
              title : 'Post',
              icon : <PostAddIcon  sx={{color:Color?.text}} />,
              link : '/add-post/',
              dropdown : [ ],
            },

            {
              title : 'Create Discussion',
              icon : <AddCommentIcon  sx={{color:Color?.text}} />,
              link : '/add-discussion/',
              dropdown : [ ],
            },

            {
              title : 'Terms & Conditions',
              icon : <GavelIcon  sx={{color:Color?.text}} />,
              link : '/terms/',
              dropdown : [ ],
            },

            {
              title : 'Privacy Policy',
              icon : <ShieldTwoToneIcon  sx={{color:Color?.text}} />,
              link : '/privacy/',
              dropdown : [ ],
            },

            {
              title : 'Support',
              icon : <SupportAgentIcon  sx={{color:Color?.text}} />,
              link : '/support/',
              dropdown : [ ],
            },

            {
              title : 'Logout',
              dropdown : [],
              icon : <LogoutIcon sx={{color:Color?.text}} />,
              link : '/logout/',
            },
  
        ],
    },
  
  ]
 
  const [ items , setItems ] = useState(LeftbarItems)

  useEffect(() => {
    setItems(logged?AccountLeftbarItems:LeftbarItems)
  } , [user])

  const [isTopBarFixed, setIsTopBarFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 64) {
      setIsTopBarFixed(true);
    } else {
      setIsTopBarFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logout = async () => {

    try {

        await API.post(`/user-logout/`, Vals?.POST_HEADER)

        .then(function (response) {

          if (response?.data?.status === 'ok') {
              setlogged(false)
              setIslogged(false)
              setUser(null)
              nav('/')
          } else {
              console.log(response?.data?.msg)
          }
       
        })
  
    } catch (error) {

      console.log(error)

    }

  }
 
  return (

      <Box variant='outlined'  sx={[Style?.Contsiner]}>
 
        { items?.map((val, mainKey) =>  (

            <List key={mainKey}  sx={Style?.List}  component="nav" aria-labelledby="nested-list-subheader" >
               
              { val.list.map((listVal, midKey) => (

                  <ListItemButton 
                    key={midKey} 
                    sx={[{ 
                        borderRadius:1, 
                        mt:1,
                        borderStyle:'solid',
                        borderWidth:0,
                        borderColor:Color?.main, 
                        fontWeight:'bold',},
                        {'&:hover': {backgroundColor:Color?.main},
                        },]}
                        onClick={() => listVal.title === 'Logout' ? logout() : nav(listVal.link)}
                        >
                      
                      <ListItemIcon  sx={{color:'primary.text'}} >

                        {listVal.icon}

                      </ListItemIcon>
                    
                      <ListItemText  primary={listVal.title} sx={{color:'primary.text'}} />

                  </ListItemButton>

              ))}
                   
            </List>
          )   

        )}
 
      </Box>
  
 
    
  )
}

export default SideBar