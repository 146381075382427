import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { Btn, PageHeader, TextInput } from '../../components';
import SendIcon from '@mui/icons-material/Send';
import { Style } from './Style';
import { useData } from '../../config/Provider';
import { CalculateTime } from '../../config/functions';
import FiberSmartRecordTwoToneIcon from '@mui/icons-material/FiberSmartRecordTwoTone';

const Chat = () => {
    const [data, setData] = useState([]);
    const [msgText, setMsgText] = useState('');
    const [page, setPage] = useState(0);
    const [check, setCheck] = useState(false);
    const { user, socket, online } = useData();
    const chatContainerRef = useRef(null);
    document.title = 'SYPHEX | Chat';
  
    const Msg = ({ item }) => (
      <Box sx={item?.user_id == user ? [Style?.me] : [Style?.not]}>
        <Typography sx={{ color: 'white', fontSize: 12 }}>{item?.sender}</Typography>
        <Typography sx={{ color: 'white' }}> {item?.text} </Typography>
        <Typography sx={{ color: 'white', fontSize: 10 }}>{CalculateTime(item?.date)}</Typography>
      </Box>
    );
  
    useEffect(() => {
      socket?.emit('allTest', { page: page });
  
      socket?.on('rcAll', async (msg) => {
        setData(msg);
      });
  
      socket?.on('RCmessage', (msg) => {
        setData((msgs) => [...msgs, msg]);
        scrollToBottom();
      });
 
    }, []);
  
    const scrollToBottom = () => {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    };
  
    const sendMsg = () => {
      if (!msgText.trim()) return false;
      socket.emit('SEmessage', { text: msgText, user_id: user });
      setMsgText('');
    };
  
    // useEffect(() => {
    //     socket?.emit('Oldmessage', { page: page });
    //     if (page >= 10) {
    //       socket?.on('RCOLDmessage', (msg) => setData([...msg, ...data]));
    //     }
    // }, [page]);
 
  
    useEffect(() => {
      scrollToBottom();
    }, [data])

    // const handleScroll = () => {
    //     if (chatContainerRef?.current) {
    //         if (chatContainerRef.current.scrollTop == 0) {
    //             setPage((prevPage) =>  prevPage + 10  );
    //         }
    //     }
    //   };
      
    //   useEffect(() => {
    //     if (chatContainerRef?.current) {
    //       chatContainerRef.current.addEventListener('scroll', handleScroll);
    //     }
    //     return () => {
    //       if (chatContainerRef?.current) {
    //         chatContainerRef.current.removeEventListener('scroll', handleScroll);
    //       }
    //     };
    //   }, []);

    return (
        <Box sx={Style?.Container}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" color="primary.text">
                Chat
                </Typography>

                <Typography variant="p" color="primary.text" fontSize={18}>
                {online} <FiberSmartRecordTwoToneIcon sx={{ color: '#64d964', fontSize: 18 }} />
                </Typography>
            </Box>
            <Box sx={Style?.ChatContainer} ref={chatContainerRef}>
                {data?.map((item, key) => (
                <Box sx={{ width: '100%', mt: 1 }} key={key}>
                    <Msg {...{ item }} />
                </Box>
                ))}
            </Box>
            <Box sx={Style?.InputContainer}>
                <Box sx={{ width: '90%' }}>
                <TextInput {...{ placeholder: 'Type...', value: msgText, set: setMsgText }} />
                </Box>
                <IconButton aria-label="send" color="primary" sx={{ zIndex: 10 }} onClick={sendMsg}>
                <SendIcon sx={{ color: 'primary.text', fontSize: 25, mt: 2.5 }} />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Chat;
