import { Avatar, CardMedia, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams, useRouteError } from 'react-router-dom'
import { Btn, DiscussionsCard, Empty, FollowerCard, VerifiedIcon } from '../../components'
import PostCard from '../../components/postCard/PostCard'
import { API } from '../../config/functions'
import { useData } from '../../config/Provider'
import Vals from '../../config/Vars'
import { Style } from './Style'
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

const Profile = ({nav}) => {
  
  const {user,logged} = useData()
  const [data , setData] = useState([])
  const [posts , setPosts] = useState([])
  const [discussions , setDiscussions] = useState([])
  const [followers , setFollowers] = useState([])
  const [following , setFollowing] = useState([])
  const [IsFollowing , setIsFollowing] = useState(false)
  const [loading , setLoading] = useState(true)
  const [check , setCheck] = useState(true)
  const { id } = useParams()
  
  const myDivRef = useRef(null);
  const [divWidth, setDivWidth] = useState(null)
  const [page, setPage] = useState(0)
  const [tabContent, setTabContent] = useState('posts')

  const getPosts = async () => {
    await API.get(`${user ? `/posts-profile/${id}/${page}` : `/profile-posts/${id}/${page}`}` , Vals?.GET_HEADER )
    .then( ( response ) => {
      if (page == 0) {
        setPosts(response?.data?.data);
  
      } else {
        if ( response?.data?.data?.length == 0 ) {
          setCheck(false)
        } else {
          setCheck(true)
          setPosts((prevData) => [...prevData, ...response?.data?.data]);
        }
        
      }
      setLoading(false)
      document.title=`SYPHEX | @${response?.data?.data[0]?.user_username}` 
    })
    .catch((e) => console.log(e) )
  }

  const getData =  async () => {
    await API.get(user ? `/profile-follow/${id}` : `/profile/${id}` , Vals?.GET_HEADER )
    .then( ( response ) => {
        setData( response?.data?.data[0] )
        setIsFollowing(response?.data?.isFollowed)
        setDiscussions( response?.data?.discussions )
        setFollowing(response?.data?.following)
        setFollowers(response?.data?.followers)
    })
    .catch((e) => console.log(e) )
  }

  const follow =  async () => {
    if (!logged) return false
    await API.post(`/follow/` , {id : id} ,  Vals?.POST_HEADER )
    .then( ( response ) => {
      setIsFollowing(response?.data?.check)
    })
    .catch((e) => console.log(e) )
  }
 
  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const documentHeight = document.documentElement.scrollHeight;
  
    if ( scrollY + windowHeight >= documentHeight  ) {
      setPage((prevPage) => check ? prevPage + 10 : page );
    }

  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, [check]);  

  useEffect(() => {
    getPosts()
  } , [page,id])
 
  useEffect( () => {
    getData()
    setTabContent('posts')
    if (myDivRef.current) {
      setDivWidth(myDivRef.current.offsetWidth);
    }
  } , [id])

  const BorderColor = (e) => {
      var val = 'transparent'
      if (e == 0) {
        val = 'transparent'
      } else if ( e == 1 ) {
        val = '#d0cccc'
      } else if ( e == 2 ) {
        val = '#ceb99d'
      } else if ( e == 3 ) { 
        val = '#c9ac2e'
      }
      return val
  }

  const coverPhoto =   data?.user_cover_photo ? `${Vals?.M_API}/covers/${data?.user_cover_photo}` : '/images/cover.png'  
  const profilePhoto = data?.user_profile_pic ?  `${Vals?.M_API}/profile-pictures/${data?.user_profile_pic}` : '/logo.png'
  
  return (
   
          <Box sx={Style?.root}>
            <Box sx={Style?.coverPhotoContainer} ref={myDivRef}>
                <CardMedia sx={Style?.coverPhoto} image={coverPhoto} alt='cover' priority="true"> 
                
                  <Box sx={Style?.profileInfoContainer}>
                    
                    <Avatar sx={[Style?.profilePicture,{
                      borderStyle:'solid',
                      borderWidth:2,
                      borderRadius:100,
                      borderColor:BorderColor(data?.accountLevel)
                    }]} src={profilePhoto} alt='/logo.png' priority="true"/>
                    <Box sx={Style?.textInfo}>

                      <Typography variant='h4' sx={{ display:'flex',alignItems:'center', color:'primary.text',fontSize:{xs:19,md:25}}}>@{data?.user_username}
                        {data?.user_verified === 'y' && ( <VerifiedRoundedIcon sx={{color:'gold',fontSize:{xs:18,md:25} , ml : 1 }} />)}
                      </Typography>

                      <Box sx={Style?.ActivityText}>
                        <Typography variant='h6' ml={{xs:0,md:0}} fontSize={{xs:12,md:15}}  sx={{color:'primary.text'}}>{data?.votes?data?.votes:0} Karma</Typography>
                        <Typography variant='h6' ml={{xs:4.5,md:7.5}} fontSize={{xs:12,md:15}} sx={{color:'primary.text'}}>{data?.post_count} Posts </Typography>
                      </Box>

                      <Box sx={Style?.ActivityText}>
                        <Typography variant='h6' ml={{xs:0,md:0}} fontSize={{xs:12,md:15}} sx={{color:'primary.text'}}>{data?.followers} Followers </Typography>
                        <Typography variant='h6' ml={{xs:2.5,md:5}} fontSize={{xs:12,md:15}} sx={{color:'primary.text'}}>{data?.following} Following </Typography>
                      </Box>

                    </Box>

                    <Box>

                      {user == id && (
                        <IconButton color="primary" aria-label="add to shopping cart" onClick={() => nav('/settings/')}>
                          <SettingsTwoToneIcon sx={{color:'primary.text',fontSize:{xs:25,md:39}}} />
                        </IconButton>
                      )}

                      {user != id && logged && (
                        <Box mr={1}>
                          <Btn {...{title:IsFollowing?'Following':'Follow',func:follow}}/>
                        </Box>
                      )}
                      
                    </Box>
                   
                  </Box>
                  
                </CardMedia>

            </Box>


            <Box sx={Style?.tabsContainer}>

              <Box sx={[Style?.tabBtn,{borderColor:tabContent === 'posts' ? 'primary.blue':'primary.border',}]} onClick={() => setTabContent('posts')}>
                <Typography variant='p' fontSize={{xs:12,md:15}} color='primary.text'> Posts </Typography>
              </Box>

              <Box sx={[Style?.tabBtn ,{borderColor:tabContent === 'discussions' ? 'primary.blue':'primary.border',}]} onClick={() => setTabContent('discussions')}>
                <Typography variant='p' fontSize={{xs:12,md:15}} color='primary.text'> Discussions </Typography>
              </Box>

              <Box sx={[Style?.tabBtn ,{borderColor:tabContent === 'followers' ? 'primary.blue':'primary.border',}]} onClick={() => setTabContent('followers')}>
                <Typography variant='p' fontSize={{xs:12,md:15}} color='primary.text'> Followers </Typography>
              </Box>

              <Box sx={[Style?.tabBtn ,{borderColor:tabContent === 'followings' ? 'primary.blue':'primary.border',}]} onClick={() => setTabContent('followings')}>
                <Typography variant='p' fontSize={{xs:12,md:15}} color='primary.text'> Following </Typography>
              </Box>
 
            </Box>

            {tabContent === 'posts' && (
              <Box sx={{width:'100%',mt:3}}>
                {posts?.length > 0 ? posts?.map((post,key) => <PostCard key={key} {...{nav,post}}/>) : <Empty  {...{text:'not posted yet'}}/> }
              </Box>
            )}

            {tabContent === 'discussions' && (
              <Box sx={{width:'100%',mt:3}}>
                {discussions?.length > 0 ? discussions?.map((item,key) => <DiscussionsCard key={key} {...{nav,item}}/>) : <Empty  {...{text:'no discussions found'}}/> }
              </Box>
            )}

            {tabContent === 'followings' && (
              <Box sx={{width:'100%',mt:3}}>
                {followers?.length > 0 ? followers?.map((item,key) => <FollowerCard key={key} {...{nav,item}}/>) : <Empty  {...{text:'no users found'}}/> }
              </Box>
            )}

            {tabContent === 'followers' && (
              <Box sx={{width:'100%',mt:3}}>
                {following?.length > 0 ? following?.map((item,key) => <FollowerCard key={key} {...{nav,item}}/>) : <Empty  {...{text:'no users found'}}/> }
              </Box>
            )}

          </Box>
 
  )
}

export default Profile