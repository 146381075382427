import React from 'react'
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import { Box } from '@mui/material';

const VerifiedIcon = () => {
  return (
    <Box sx={{position:'relative'}}>
        <VerifiedRoundedIcon sx={{color:'gold',position:'absolute',right:8,bottom:0,fontSize:18 }} />
    </Box>
  )
}

export default VerifiedIcon