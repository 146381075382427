export const Style = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      profileInfoContainer:{
        display: 'flex',
        flexDirection: 'row',
        width:'100%',
        height:'100%',
        alignItems: 'center',
        pl:{xs:0,md:5},
        justifyContent:'flex-start',
        backgroundColor:'rgba(0,0,0,0.6)'
      },
      coverPhoto : {
        borderWidth: 0.2,
        borderStyle: `solid`,
        width:'100%',
        height:'100%',
        borderColor: `gray`,
        borderRadius:3 ,
      },
      textInfo:{
        ml:{xs:2,md:5},
        width:'50%'
      },
   
      ActivityText:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'flex-start',
        alignItems: 'center',
      },
      coverPhotoContainer: {
        height: {xs:150,md:200},
        width:'100%',        
        borderColor: `gray`,
        borderWidth: 0.2,
        borderRadius:3 ,
        borderStyle: `solid`,
      },
      profilePicture: {
        width:100,
        height:100,
        border: `2px solid lightgray`,
      },
 
      tabsContainer : {
        mt:2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'space-around',
        alignItems: 'center',
        width:'100%'
      } ,
      tabBtn : {
        cursor : 'pointer',
        borderStyle: `solid`,        
        border: 0.5,
        borderRadius:2,        
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'space-around',
        alignItems: 'center',
        width:'30%',
        pt:1,
        paddingBottom:1
      } ,
 
}