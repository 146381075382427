import { Grid, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useData } from '../../config/Provider'
import { DiscussionsCard, PostCard } from '../../components'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'


export default function Discussions({nav}) {
  document.title=`SYPHEX | Discussions` 

  const [ data , setData ] = useState([])
  const [ page , setPage ] = useState(0)

  useEffect(() => {
    API.get(`/all-discussions/` , Vals?.GET_HEADER).then((res) => setData(res?.data?.data) ).catch((e) => console.log(e))
  } , [])

  return data?.map( ( item , key) => <DiscussionsCard key={key} {...{nav,item}} /> )
 
}
