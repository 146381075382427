import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { API } from '../../config/functions'
import Vals from '../../config/Vars'
import { useParams } from 'react-router'
import { Box, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { Style } from './Style'

const CategoryNews = ({nav}) => {

    const [data, setData] = useState([])
    const {id} = useParams()

    useEffect(() => {
        API.get(`/all-blogs/${id}` , Vals?.GET_HEADER).then((res) => setData(res?.data?.data) ).catch((e) => console.log(e))
    } , [id])

    const Card = ({item}) => (
        <Box onClick={() => nav(`/blog/${item?.blog_id}`)} 
            sx={{ 
                backgroundColor:'primary.main' ,  
                display: 'flex' , 
                flexDirection:'column',
                mt : 1 , 
                width : '95%' ,
                height:'auto',
                cursor:'pointer'}}>
            <CardMedia
                sx={{ height:300}}
                image={`${Vals?.M_API}/blogs/${item?.blog_cover?item?.blog_cover:'empty.png'}` }
                title="Blog Cover Image"
            />
            <div style={{  display: 'flex',flexDirection: 'column' }}>
                <CardContent >
                    <Typography component="h5" variant="h5" color={'primary.text'}>
                    {item?.blog_title}
                    </Typography>
                    <Typography variant="subtitle1" color={'primary.text'}>
                    {  new Date(item?.blog_date).toLocaleString()}
                    </Typography>
                </CardContent>
            </div>
        </Box>
    )

    return (
        <Box sx={[Style?.Container ]}>
            <Typography variant='h6' color='primary.text'> Top News ( {data[0]?.category_title} ) </Typography>
            <Box sx={[Style?.InnerContainer , { borderColor:'primary.border' }]}>
                {data?.map((item, index) => (
                    <Card {...{item}}  />
                ))} 
            </Box>
        </Box>
    )
}

export default CategoryNews